import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { RlasApiModule } from './rlas-api.module';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { SecurityGroup } from './model';

/**
 * Injectable for controlling access to RLAS Management pages.
 */
@Injectable({
  providedIn: RlasApiModule
})
export class RlasManagementGuardService {

  /**
   * Creates an instance of RLAS Mapping Guard Service.
   *
   * @param router The router.
   * @param userService The user service.
   */
  constructor(private router: Router,
    private userService: UserService

  ) { }

  /**
   * Determine if the route can be activate.
   * 
   * @param next The {@link ActivatedRouteSnapshot}.
   * @param state The {@link RouterStateSnapshot}.
   * @returns Emits an observable that indicates if route can be activated.
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.me$.pipe(
      map(user => user.groups.includes(SecurityGroup.rlasConfigAccess) || this.router.createUrlTree(['/unauthorised', 'RLAS Management']))
    );
  }
}

export const RlasManagementGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(RlasManagementGuardService).canActivate(next, state);
};
